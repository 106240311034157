<template>
  <div>
    <Transition name="slideright-outfade">
      <section id="game" class="section p-5" :class="$parent.questionStateClass"
        v-if="!$parent.ShowVideoLoop && $parent.currQuestionIndex != -1 && $parent.serverState < 3 && $parent.currQuestion != undefined">
        <div v-if="$parent.options.logo" class="logo">
          <img :src="$parent.options.logo.url">
        </div>
        <div class="columns m-0">
          <div class="column is-4 is-flex is-flex-direction-column">
            <div class="question-number pb-2 is-uppercase" v-if="$parent.questionState != 0">
              {{ $t('exp_trivia.questionNumber', [$parent.currQuestionIndex + 1, $parent.selectedQuestions.length]) }}
            </div>
            <section class="main is-flex is-flex-direction-column is-justify-content-space-between">
              <div class="question" v-if="$parent.questionState != 0">
                <div class="question-text p-5 has-text-white-bis has-background-primary-dark"
                  :style="'background-image: url('+encodeURI($parent.currQuestion.image ? $parent.currQuestion.image.url : '')+')'">

                  <div>{{ $parent.currQuestion.prompt }}</div>
                </div>
                <!-- Options -->
                <div id="options" v-if="$parent.questionState > 1">
                  <transition-group appear @before-enter="beforeEnter" @enter="enter">
                    <div v-for="(answer) in $parent.OptionsToShow" :key="answer.id" class="option"
                      :class="{ correctAnswer: answer.id == $parent.currQuestion.id && $parent.questionState == 3 }"
                      :data-index="answer.index">
                      <div class="letter has-background-primary-dark">
                        <div>
                          {{ $parent.intToChar(answer.index) }}
                        </div>
                      </div>
                      <div class="text is-flex is-align-items-center">
                        <div>{{ answer.option }}</div>
                      </div>
                      <!-- Caritas de los que contestaron -->
                      <div class="faces"
                        :class="{threeFaces: $parent.currQuestion.options.find(x => x.id == answer.id).guessed.length == 3}">
                        <player-avatar v-show="$parent.questionState == 3"
                          v-for="player in $parent.currQuestion.options.find(x => x.id == answer.id).guessed"
                          :key="player.dbid" :player="player" :scale="1.7" :showUsername="false" />
                      </div>
                    </div>
                  </transition-group>
                </div>
              </div>

              <!-- Info text -->
              <div id="info-text"
                v-if="$parent.questionState == 3 && $parent.currQuestion.extraInfo && $parent.currQuestion.extraInfo != ''">
                <div>
                  {{$parent.currQuestion.extraInfo}}
                </div>
              </div>
              <progress-circle
                :class="{ active: $parent.questionState > 1 && $parent.questionState < 3 }"
                :progress="1 - $parent.clockNormalized"
                :diameter="150"
                :border="14"
                progress-color="#ccc"
                track-color="var(--primary)"
                inner-color="#fff">
                <span class="is-size-3 has-text-weight-bold">
                  {{ $parent.clockDisplay }}
                </span>
              </progress-circle>

            </section>
          </div>
          <!-- cityspace -->
          <div id="cities" class="column is-8 p-0 pl-5">
            <div class="cities" :class="'cities-' + $parent.players.length">
              <div v-for="(player, playerIndex) in $parent.players.length" class="city" :key="player.dbid">

                <div>
                  <div v-for="(city, cityIndex) in GetAllCityStates(playerIndex)" :key="city.id">
                    <transition @before-enter="beforeEnterCityChange" @enter="enterCityChange" @leave="leaveCityChange">
                      <video autoplay loop muted
                        crossorigin="anonymous"
                        v-show="ScoreOfPlayer(playerIndex) == cityIndex && ThisCityExists(playerIndex)"
                        :class="CityRenderClass(playerIndex, cityIndex)">
                        <source :src="GetCity(playerIndex, cityIndex).url" type="video/webm" />
                      </video>
                    </transition>
                  </div>
                  <svg class="ground-city" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1080 1080"
                    style="enable-background:new 0 0 1080 1080;" xml:space="preserve">

                    <path :fill="'#' + $parent.players[playerIndex].color" class="st0" d="M1073.9,561.01c0.02,12,0.03,24,0.06,36c0.02,11.22-5.29,19.37-14.79,24.77
		c-10.97,6.24-22.15,12.13-33.24,18.17c-22.62,12.31-45.23,24.64-67.87,36.92c-13.15,7.13-26.37,14.13-39.51,21.28
		c-21.75,11.82-43.45,23.73-65.19,35.57c-12.4,6.76-24.85,13.44-37.27,20.17c-23.37,12.67-46.73,25.37-70.11,38.03
		c-13.45,7.28-26.95,14.46-40.38,21.76c-21.46,11.67-42.87,23.41-64.32,35.1c-12.11,6.6-24.25,13.14-36.39,19.69
		c-12.87,6.95-25.7,13.97-38.63,20.79c-10,5.28-20.78,7.54-32.07,7.78c-12.4,0.26-24.63-0.77-35.92-6.22
		c-7.75-3.74-15.1-8.34-22.56-12.69c-18.64-10.88-37.24-21.82-55.85-32.75c-13.74-8.07-27.46-16.19-41.21-24.25
		c-15.18-8.9-30.39-17.75-45.58-26.64c-13.75-8.06-27.48-16.16-41.23-24.22c-15.33-8.98-30.67-17.93-46-26.91
		c-13.75-8.06-27.46-16.18-41.21-24.24c-15.18-8.9-30.39-17.75-45.58-26.64c-13.75-8.06-27.48-16.16-41.23-24.22
		c-15.33-8.98-30.68-17.92-46-26.91c-13.88-8.16-27.74-16.35-41.6-24.56c-4.56-2.7-9.29-5.09-13.56-8.33
		c-7.12-5.39-10.93-12.15-10.8-21.24c0.18-12.82,0.05-25.64,0.05-38.48c1.98-0.03,1.75,1.42,2.12,2.43
		c1.7,4.62,4.47,8.32,8.53,11.29c5.48,4.01,10.74,8.34,15.94,12.72c6.13,5.17,12.69,9.6,19.93,13.08
		c11.09,5.33,21.96,11.05,32.43,17.56c8.08,5.02,17.08,8.02,26.35,10.19c18.17,4.26,32.49,14.21,44.27,28.65
		c9.23,11.3,19.01,22.24,32.1,29.54c6.86,3.83,14.22,6.44,21.48,9.21c10.14,3.87,18.56,10.24,26.74,17.04
		c9.97,8.29,20.01,16.44,31.89,21.94c8.03,3.73,16.84,4.92,25.14,7.69c17.61,5.87,35.6,10.76,52.32,19.04
		c9.58,4.74,17.88,11.4,25.88,18.45c14.05,12.39,26.79,26.35,43.04,36.13c3.13,1.88,6.16,4,9.47,5.5
		c14.01,6.34,26.65,14.88,39,23.92c7.59,5.56,16.27,7.95,25.48,8.91c3.14,0.33,6.12,1.19,9.04,2.49c7.91,3.51,16.21,5.48,24.84,6.61
		c15.66,2.05,30.15-1.64,44.49-7.31c11.56-4.57,23.39-8.48,34.95-13.06c18.58-7.37,36.47-16.15,52.45-28.32
		c4.21-3.21,7.7-7.36,11.47-11.13c8.48-8.48,17.2-16.6,28-22.17c5.76-2.97,11.76-5.36,17.79-7.68c10.09-3.87,19.74-8.67,29.36-13.57
		c10.56-5.38,21.61-9,33.56-10.06c8.19-0.72,16.58-1.54,23.46-7.05c4.98-3.99,8.93-9.02,12.82-13.91
		c6.42-8.09,14.08-13.5,24.36-14.79c7.27-0.91,13.09-4.41,18.2-9.38c7.86-7.64,15.05-16.05,24.26-22.19
		c11.74-7.82,23.43-15.78,36.74-20.85c5.78-2.2,11.95-2.57,17.98-3.39c10-1.36,19.23-4.95,28.36-8.91
		c14.56-6.32,27.99-14.48,40.01-24.95c9.55-8.33,20.42-14.79,31.34-21.1c9.06-5.24,18.33-10.11,27.42-15.29
		c6.07-3.46,10.84-8.19,13.13-15.02C1072.65,560.98,1073.28,561,1073.9,561.01z" />
                    <path :fill="'#' + $parent.players[playerIndex].color" class="st0" d="M1073.9,561.01c0.02,12,0.03,24,0.06,36c0.02,11.22-5.29,19.37-14.79,24.77
		c-10.97,6.24-22.15,12.13-33.24,18.17c-22.62,12.31-45.23,24.64-67.87,36.92c-13.15,7.13-26.37,14.13-39.51,21.28
		c-21.75,11.82-43.45,23.73-65.19,35.57c-12.4,6.76-24.85,13.44-37.27,20.17c-23.37,12.67-46.73,25.37-70.11,38.03
		c-13.45,7.28-26.95,14.46-40.38,21.76c-21.46,11.67-42.87,23.41-64.32,35.1c-12.11,6.6-24.25,13.14-36.39,19.69
		c-12.87,6.95-25.7,13.97-38.63,20.79c-10,5.28-20.78,7.54-32.07,7.78c-12.4,0.26-24.63-0.77-35.92-6.22
		c-7.75-3.74-15.1-8.34-22.56-12.69c-18.64-10.88-37.24-21.82-55.85-32.75c-13.74-8.07-27.46-16.19-41.21-24.25
		c-15.18-8.9-30.39-17.75-45.58-26.64c-13.75-8.06-27.48-16.16-41.23-24.22c-15.33-8.98-30.67-17.93-46-26.91
		c-13.75-8.06-27.46-16.18-41.21-24.24c-15.18-8.9-30.39-17.75-45.58-26.64c-13.75-8.06-27.48-16.16-41.23-24.22
		c-15.33-8.98-30.68-17.92-46-26.91c-13.88-8.16-27.74-16.35-41.6-24.56c-4.56-2.7-9.29-5.09-13.56-8.33
		c-7.12-5.39-10.93-12.15-10.8-21.24c0.18-12.82,0.05-25.64,0.05-38.48c1.98-0.03,1.75,1.42,2.12,2.43
		c1.7,4.62,4.47,8.32,8.53,11.29c5.48,4.01,10.74,8.34,15.94,12.72c6.13,5.17,12.69,9.6,19.93,13.08
		c11.09,5.33,21.96,11.05,32.43,17.56c8.08,5.02,17.08,8.02,26.35,10.19c18.17,4.26,32.49,14.21,44.27,28.65
		c9.23,11.3,19.01,22.24,32.1,29.54c6.86,3.83,14.22,6.44,21.48,9.21c10.14,3.87,18.56,10.24,26.74,17.04
		c9.97,8.29,20.01,16.44,31.89,21.94c8.03,3.73,16.84,4.92,25.14,7.69c17.61,5.87,35.6,10.76,52.32,19.04
		c9.58,4.74,17.88,11.4,25.88,18.45c14.05,12.39,26.79,26.35,43.04,36.13c3.13,1.88,6.16,4,9.47,5.5
		c14.01,6.34,26.65,14.88,39,23.92c7.59,5.56,16.27,7.95,25.48,8.91c3.14,0.33,6.12,1.19,9.04,2.49c7.91,3.51,16.21,5.48,24.84,6.61
		c15.66,2.05,30.15-1.64,44.49-7.31c11.56-4.57,23.39-8.48,34.95-13.06c18.58-7.37,36.47-16.15,52.45-28.32
		c4.21-3.21,7.7-7.36,11.47-11.13c8.48-8.48,17.2-16.6,28-22.17c5.76-2.97,11.76-5.36,17.79-7.68c10.09-3.87,19.74-8.67,29.36-13.57
		c10.56-5.38,21.61-9,33.56-10.06c8.19-0.72,16.58-1.54,23.46-7.05c4.98-3.99,8.93-9.02,12.82-13.91
		c6.42-8.09,14.08-13.5,24.36-14.79c7.27-0.91,13.09-4.41,18.2-9.38c7.86-7.64,15.05-16.05,24.26-22.19
		c11.74-7.82,23.43-15.78,36.74-20.85c5.78-2.2,11.95-2.57,17.98-3.39c10-1.36,19.23-4.95,28.36-8.91
		c14.56-6.32,27.99-14.48,40.01-24.95c9.55-8.33,20.42-14.79,31.34-21.1c9.06-5.24,18.33-10.11,27.42-15.29
		c6.07-3.46,10.84-8.19,13.13-15.02C1072.65,560.98,1073.28,561,1073.9,561.01z" />
                    <path :fill="'#' + $parent.players[playerIndex].color" class="st0" d="M1073.9,561.01c0.02,12,0.03,24,0.06,36c0.02,11.22-5.29,19.37-14.79,24.77
		c-10.97,6.24-22.15,12.13-33.24,18.17c-22.62,12.31-45.23,24.64-67.87,36.92c-13.15,7.13-26.37,14.13-39.51,21.28
		c-21.75,11.82-43.45,23.73-65.19,35.57c-12.4,6.76-24.85,13.44-37.27,20.17c-23.37,12.67-46.73,25.37-70.11,38.03
		c-13.45,7.28-26.95,14.46-40.38,21.76c-21.46,11.67-42.87,23.41-64.32,35.1c-12.11,6.6-24.25,13.14-36.39,19.69
		c-12.87,6.95-25.7,13.97-38.63,20.79c-10,5.28-20.78,7.54-32.07,7.78c-12.4,0.26-24.63-0.77-35.92-6.22
		c-7.75-3.74-15.1-8.34-22.56-12.69c-18.64-10.88-37.24-21.82-55.85-32.75c-13.74-8.07-27.46-16.19-41.21-24.25
		c-15.18-8.9-30.39-17.75-45.58-26.64c-13.75-8.06-27.48-16.16-41.23-24.22c-15.33-8.98-30.67-17.93-46-26.91
		c-13.75-8.06-27.46-16.18-41.21-24.24c-15.18-8.9-30.39-17.75-45.58-26.64c-13.75-8.06-27.48-16.16-41.23-24.22
		c-15.33-8.98-30.68-17.92-46-26.91c-13.88-8.16-27.74-16.35-41.6-24.56c-4.56-2.7-9.29-5.09-13.56-8.33
		c-7.12-5.39-10.93-12.15-10.8-21.24c0.18-12.82,0.05-25.64,0.05-38.48c1.98-0.03,1.75,1.42,2.12,2.43
		c1.7,4.62,4.47,8.32,8.53,11.29c5.48,4.01,10.74,8.34,15.94,12.72c6.13,5.17,12.69,9.6,19.93,13.08
		c11.09,5.33,21.96,11.05,32.43,17.56c8.08,5.02,17.08,8.02,26.35,10.19c18.17,4.26,32.49,14.21,44.27,28.65
		c9.23,11.3,19.01,22.24,32.1,29.54c6.86,3.83,14.22,6.44,21.48,9.21c10.14,3.87,18.56,10.24,26.74,17.04
		c9.97,8.29,20.01,16.44,31.89,21.94c8.03,3.73,16.84,4.92,25.14,7.69c17.61,5.87,35.6,10.76,52.32,19.04
		c9.58,4.74,17.88,11.4,25.88,18.45c14.05,12.39,26.79,26.35,43.04,36.13c3.13,1.88,6.16,4,9.47,5.5
		c14.01,6.34,26.65,14.88,39,23.92c7.59,5.56,16.27,7.95,25.48,8.91c3.14,0.33,6.12,1.19,9.04,2.49c7.91,3.51,16.21,5.48,24.84,6.61
		c15.66,2.05,30.15-1.64,44.49-7.31c11.56-4.57,23.39-8.48,34.95-13.06c18.58-7.37,36.47-16.15,52.45-28.32
		c4.21-3.21,7.7-7.36,11.47-11.13c8.48-8.48,17.2-16.6,28-22.17c5.76-2.97,11.76-5.36,17.79-7.68c10.09-3.87,19.74-8.67,29.36-13.57
		c10.56-5.38,21.61-9,33.56-10.06c8.19-0.72,16.58-1.54,23.46-7.05c4.98-3.99,8.93-9.02,12.82-13.91
		c6.42-8.09,14.08-13.5,24.36-14.79c7.27-0.91,13.09-4.41,18.2-9.38c7.86-7.64,15.05-16.05,24.26-22.19
		c11.74-7.82,23.43-15.78,36.74-20.85c5.78-2.2,11.95-2.57,17.98-3.39c10-1.36,19.23-4.95,28.36-8.91
		c14.56-6.32,27.99-14.48,40.01-24.95c9.55-8.33,20.42-14.79,31.34-21.1c9.06-5.24,18.33-10.11,27.42-15.29
		c6.07-3.46,10.84-8.19,13.13-15.02C1072.65,560.98,1073.28,561,1073.9,561.01z" />
                    <path :fill="'#' + $parent.players[playerIndex].color" class="st1" d="M1073.9,561.01c0.02,12,0.03,24,0.06,36c0.02,11.22-5.29,19.37-14.79,24.77
		c-10.97,6.24-22.15,12.13-33.24,18.17c-22.62,12.31-45.23,24.64-67.87,36.92c-13.15,7.13-26.37,14.13-39.51,21.28
		c-21.75,11.82-43.45,23.73-65.19,35.57c-12.4,6.76-24.85,13.44-37.27,20.17c-23.37,12.67-46.73,25.37-70.11,38.03
		c-13.45,7.28-26.95,14.46-40.38,21.76c-21.46,11.67-42.87,23.41-64.32,35.1c-12.11,6.6-24.25,13.14-36.39,19.69
		c-12.87,6.95-25.7,13.97-38.63,20.79c-10,5.28-20.78,7.54-32.07,7.78c-12.4,0.26-24.63-0.77-35.92-6.22
		c-7.75-3.74-15.1-8.34-22.56-12.69c-18.64-10.88-37.24-21.82-55.85-32.75c-13.74-8.07-27.46-16.19-41.21-24.25
		c-15.18-8.9-30.39-17.75-45.58-26.64c-13.75-8.06-27.48-16.16-41.23-24.22c-15.33-8.98-30.67-17.93-46-26.91
		c-13.75-8.06-27.46-16.18-41.21-24.24c-15.18-8.9-30.39-17.75-45.58-26.64c-13.75-8.06-27.48-16.16-41.23-24.22
		c-15.33-8.98-30.68-17.92-46-26.91c-13.88-8.16-27.74-16.35-41.6-24.56c-4.56-2.7-9.29-5.09-13.56-8.33
		c-7.12-5.39-10.93-12.15-10.8-21.24c0.18-12.82,0.05-25.64,0.05-38.48c1.98-0.03,1.75,1.42,2.12,2.43
		c1.7,4.62,4.47,8.32,8.53,11.29c5.48,4.01,10.74,8.34,15.94,12.72c6.13,5.17,12.69,9.6,19.93,13.08
		c11.09,5.33,21.96,11.05,32.43,17.56c8.08,5.02,17.08,8.02,26.35,10.19c18.17,4.26,32.49,14.21,44.27,28.65
		c9.23,11.3,19.01,22.24,32.1,29.54c6.86,3.83,14.22,6.44,21.48,9.21c10.14,3.87,18.56,10.24,26.74,17.04
		c9.97,8.29,20.01,16.44,31.89,21.94c8.03,3.73,16.84,4.92,25.14,7.69c17.61,5.87,35.6,10.76,52.32,19.04
		c9.58,4.74,17.88,11.4,25.88,18.45c14.05,12.39,26.79,26.35,43.04,36.13c3.13,1.88,6.16,4,9.47,5.5
		c14.01,6.34,26.65,14.88,39,23.92c7.59,5.56,16.27,7.95,25.48,8.91c3.14,0.33,6.12,1.19,9.04,2.49c7.91,3.51,16.21,5.48,24.84,6.61
		c15.66,2.05,30.15-1.64,44.49-7.31c11.56-4.57,23.39-8.48,34.95-13.06c18.58-7.37,36.47-16.15,52.45-28.32
		c4.21-3.21,7.7-7.36,11.47-11.13c8.48-8.48,17.2-16.6,28-22.17c5.76-2.97,11.76-5.36,17.79-7.68c10.09-3.87,19.74-8.67,29.36-13.57
		c10.56-5.38,21.61-9,33.56-10.06c8.19-0.72,16.58-1.54,23.46-7.05c4.98-3.99,8.93-9.02,12.82-13.91
		c6.42-8.09,14.08-13.5,24.36-14.79c7.27-0.91,13.09-4.41,18.2-9.38c7.86-7.64,15.05-16.05,24.26-22.19
		c11.74-7.82,23.43-15.78,36.74-20.85c5.78-2.2,11.95-2.57,17.98-3.39c10-1.36,19.23-4.95,28.36-8.91
		c14.56-6.32,27.99-14.48,40.01-24.95c9.55-8.33,20.42-14.79,31.34-21.1c9.06-5.24,18.33-10.11,27.42-15.29
		c6.07-3.46,10.84-8.19,13.13-15.02C1072.65,560.98,1073.28,561,1073.9,561.01z" />
                  </svg>

                  <player-avatar :player="$parent.players[playerIndex]" :showUsername="true" />
                  <div class="score">
                    <animated-number class="points" :number="Math.round($parent.players[playerIndex].score)"
                      :duration=".7" />
                    <div class="circle-container">
                      <div v-for="(circle,index) in $parent.selectedQuestions.length" class="circle"
                        :class="{active: index < $parent.players[playerIndex].correctAnswers }" :key="'circle-'+index">
                      </div>
                    </div>
                    <svg viewBox="0 0 211 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M210.5 65.5H0.5L118 0H210.5V65.5Z" fill="var(--primary)" />
                    </svg>
                  </div>

                  <div v-show="$parent.questionState == 3">
                    <video crossorigin="anonymous" autoplay class="transition" :ref="'transition' + playerIndex">
                      <source :src="GetTransition(ScoreOfPlayer(playerIndex)).url" type="video/webm" />
                    </video>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </section>
    </Transition>
    <Transition name="slidebottom">
      <section class="section" v-if="$parent.serverState == 3" id="finalScore">
        <template v-if="$parent.playersScoreboard[0].score > 0">
          <!-- Scoreboard -->
          <canvas id="confetti-canvas" style="position: absolute"></canvas>
          <div class="final scoreboard">

            <div class="winner">
              <div class="title">{{ $t('exp_trivia.congratulations') }}!</div>
              <div class="winnerUser userItem">
                <player-avatar :player="$parent.playersScoreboard[0]" :scale="3.3" :showUsername="false" class="mr-4" />
                <div class="player-box" :style="{ backgroundColor: `#${$parent.playersScoreboard[0].color}` }">
                  <span class="username">{{$parent.playersScoreboard[0].username}}</span>
                  <span class="score">
                    {{ Math.round($parent.playersScoreboard[0].score) }}
                  </span>
                </div>
              </div>
              <div class="city">
                <video autoplay loop muted>
                  <source :src="GetCity(winnerIndex, ScoreOfPlayer(winnerIndex)).url" type="video/webm" />
                </video>
                <svg class="ground-city" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1080 1080"
                  style="enable-background:new 0 0 1080 1080;" xml:space="preserve">
                  <path :fill="'#' + $parent.playersScoreboard[0].color" class="st0" d="M1073.9,561.01c0.02,12,0.03,24,0.06,36c0.02,11.22-5.29,19.37-14.79,24.77
		c-10.97,6.24-22.15,12.13-33.24,18.17c-22.62,12.31-45.23,24.64-67.87,36.92c-13.15,7.13-26.37,14.13-39.51,21.28
		c-21.75,11.82-43.45,23.73-65.19,35.57c-12.4,6.76-24.85,13.44-37.27,20.17c-23.37,12.67-46.73,25.37-70.11,38.03
		c-13.45,7.28-26.95,14.46-40.38,21.76c-21.46,11.67-42.87,23.41-64.32,35.1c-12.11,6.6-24.25,13.14-36.39,19.69
		c-12.87,6.95-25.7,13.97-38.63,20.79c-10,5.28-20.78,7.54-32.07,7.78c-12.4,0.26-24.63-0.77-35.92-6.22
		c-7.75-3.74-15.1-8.34-22.56-12.69c-18.64-10.88-37.24-21.82-55.85-32.75c-13.74-8.07-27.46-16.19-41.21-24.25
		c-15.18-8.9-30.39-17.75-45.58-26.64c-13.75-8.06-27.48-16.16-41.23-24.22c-15.33-8.98-30.67-17.93-46-26.91
		c-13.75-8.06-27.46-16.18-41.21-24.24c-15.18-8.9-30.39-17.75-45.58-26.64c-13.75-8.06-27.48-16.16-41.23-24.22
		c-15.33-8.98-30.68-17.92-46-26.91c-13.88-8.16-27.74-16.35-41.6-24.56c-4.56-2.7-9.29-5.09-13.56-8.33
		c-7.12-5.39-10.93-12.15-10.8-21.24c0.18-12.82,0.05-25.64,0.05-38.48c1.98-0.03,1.75,1.42,2.12,2.43
		c1.7,4.62,4.47,8.32,8.53,11.29c5.48,4.01,10.74,8.34,15.94,12.72c6.13,5.17,12.69,9.6,19.93,13.08
		c11.09,5.33,21.96,11.05,32.43,17.56c8.08,5.02,17.08,8.02,26.35,10.19c18.17,4.26,32.49,14.21,44.27,28.65
		c9.23,11.3,19.01,22.24,32.1,29.54c6.86,3.83,14.22,6.44,21.48,9.21c10.14,3.87,18.56,10.24,26.74,17.04
		c9.97,8.29,20.01,16.44,31.89,21.94c8.03,3.73,16.84,4.92,25.14,7.69c17.61,5.87,35.6,10.76,52.32,19.04
		c9.58,4.74,17.88,11.4,25.88,18.45c14.05,12.39,26.79,26.35,43.04,36.13c3.13,1.88,6.16,4,9.47,5.5
		c14.01,6.34,26.65,14.88,39,23.92c7.59,5.56,16.27,7.95,25.48,8.91c3.14,0.33,6.12,1.19,9.04,2.49c7.91,3.51,16.21,5.48,24.84,6.61
		c15.66,2.05,30.15-1.64,44.49-7.31c11.56-4.57,23.39-8.48,34.95-13.06c18.58-7.37,36.47-16.15,52.45-28.32
		c4.21-3.21,7.7-7.36,11.47-11.13c8.48-8.48,17.2-16.6,28-22.17c5.76-2.97,11.76-5.36,17.79-7.68c10.09-3.87,19.74-8.67,29.36-13.57
		c10.56-5.38,21.61-9,33.56-10.06c8.19-0.72,16.58-1.54,23.46-7.05c4.98-3.99,8.93-9.02,12.82-13.91
		c6.42-8.09,14.08-13.5,24.36-14.79c7.27-0.91,13.09-4.41,18.2-9.38c7.86-7.64,15.05-16.05,24.26-22.19
		c11.74-7.82,23.43-15.78,36.74-20.85c5.78-2.2,11.95-2.57,17.98-3.39c10-1.36,19.23-4.95,28.36-8.91
		c14.56-6.32,27.99-14.48,40.01-24.95c9.55-8.33,20.42-14.79,31.34-21.1c9.06-5.24,18.33-10.11,27.42-15.29
		c6.07-3.46,10.84-8.19,13.13-15.02C1072.65,560.98,1073.28,561,1073.9,561.01z" />
                  <path :fill="'#' + $parent.playersScoreboard[0].color" class="st0" d="M1073.9,561.01c0.02,12,0.03,24,0.06,36c0.02,11.22-5.29,19.37-14.79,24.77
		c-10.97,6.24-22.15,12.13-33.24,18.17c-22.62,12.31-45.23,24.64-67.87,36.92c-13.15,7.13-26.37,14.13-39.51,21.28
		c-21.75,11.82-43.45,23.73-65.19,35.57c-12.4,6.76-24.85,13.44-37.27,20.17c-23.37,12.67-46.73,25.37-70.11,38.03
		c-13.45,7.28-26.95,14.46-40.38,21.76c-21.46,11.67-42.87,23.41-64.32,35.1c-12.11,6.6-24.25,13.14-36.39,19.69
		c-12.87,6.95-25.7,13.97-38.63,20.79c-10,5.28-20.78,7.54-32.07,7.78c-12.4,0.26-24.63-0.77-35.92-6.22
		c-7.75-3.74-15.1-8.34-22.56-12.69c-18.64-10.88-37.24-21.82-55.85-32.75c-13.74-8.07-27.46-16.19-41.21-24.25
		c-15.18-8.9-30.39-17.75-45.58-26.64c-13.75-8.06-27.48-16.16-41.23-24.22c-15.33-8.98-30.67-17.93-46-26.91
		c-13.75-8.06-27.46-16.18-41.21-24.24c-15.18-8.9-30.39-17.75-45.58-26.64c-13.75-8.06-27.48-16.16-41.23-24.22
		c-15.33-8.98-30.68-17.92-46-26.91c-13.88-8.16-27.74-16.35-41.6-24.56c-4.56-2.7-9.29-5.09-13.56-8.33
		c-7.12-5.39-10.93-12.15-10.8-21.24c0.18-12.82,0.05-25.64,0.05-38.48c1.98-0.03,1.75,1.42,2.12,2.43
		c1.7,4.62,4.47,8.32,8.53,11.29c5.48,4.01,10.74,8.34,15.94,12.72c6.13,5.17,12.69,9.6,19.93,13.08
		c11.09,5.33,21.96,11.05,32.43,17.56c8.08,5.02,17.08,8.02,26.35,10.19c18.17,4.26,32.49,14.21,44.27,28.65
		c9.23,11.3,19.01,22.24,32.1,29.54c6.86,3.83,14.22,6.44,21.48,9.21c10.14,3.87,18.56,10.24,26.74,17.04
		c9.97,8.29,20.01,16.44,31.89,21.94c8.03,3.73,16.84,4.92,25.14,7.69c17.61,5.87,35.6,10.76,52.32,19.04
		c9.58,4.74,17.88,11.4,25.88,18.45c14.05,12.39,26.79,26.35,43.04,36.13c3.13,1.88,6.16,4,9.47,5.5
		c14.01,6.34,26.65,14.88,39,23.92c7.59,5.56,16.27,7.95,25.48,8.91c3.14,0.33,6.12,1.19,9.04,2.49c7.91,3.51,16.21,5.48,24.84,6.61
		c15.66,2.05,30.15-1.64,44.49-7.31c11.56-4.57,23.39-8.48,34.95-13.06c18.58-7.37,36.47-16.15,52.45-28.32
		c4.21-3.21,7.7-7.36,11.47-11.13c8.48-8.48,17.2-16.6,28-22.17c5.76-2.97,11.76-5.36,17.79-7.68c10.09-3.87,19.74-8.67,29.36-13.57
		c10.56-5.38,21.61-9,33.56-10.06c8.19-0.72,16.58-1.54,23.46-7.05c4.98-3.99,8.93-9.02,12.82-13.91
		c6.42-8.09,14.08-13.5,24.36-14.79c7.27-0.91,13.09-4.41,18.2-9.38c7.86-7.64,15.05-16.05,24.26-22.19
		c11.74-7.82,23.43-15.78,36.74-20.85c5.78-2.2,11.95-2.57,17.98-3.39c10-1.36,19.23-4.95,28.36-8.91
		c14.56-6.32,27.99-14.48,40.01-24.95c9.55-8.33,20.42-14.79,31.34-21.1c9.06-5.24,18.33-10.11,27.42-15.29
		c6.07-3.46,10.84-8.19,13.13-15.02C1072.65,560.98,1073.28,561,1073.9,561.01z" />
                  <path :fill="'#' + $parent.playersScoreboard[0].color" class="st0" d="M1073.9,561.01c0.02,12,0.03,24,0.06,36c0.02,11.22-5.29,19.37-14.79,24.77
		c-10.97,6.24-22.15,12.13-33.24,18.17c-22.62,12.31-45.23,24.64-67.87,36.92c-13.15,7.13-26.37,14.13-39.51,21.28
		c-21.75,11.82-43.45,23.73-65.19,35.57c-12.4,6.76-24.85,13.44-37.27,20.17c-23.37,12.67-46.73,25.37-70.11,38.03
		c-13.45,7.28-26.95,14.46-40.38,21.76c-21.46,11.67-42.87,23.41-64.32,35.1c-12.11,6.6-24.25,13.14-36.39,19.69
		c-12.87,6.95-25.7,13.97-38.63,20.79c-10,5.28-20.78,7.54-32.07,7.78c-12.4,0.26-24.63-0.77-35.92-6.22
		c-7.75-3.74-15.1-8.34-22.56-12.69c-18.64-10.88-37.24-21.82-55.85-32.75c-13.74-8.07-27.46-16.19-41.21-24.25
		c-15.18-8.9-30.39-17.75-45.58-26.64c-13.75-8.06-27.48-16.16-41.23-24.22c-15.33-8.98-30.67-17.93-46-26.91
		c-13.75-8.06-27.46-16.18-41.21-24.24c-15.18-8.9-30.39-17.75-45.58-26.64c-13.75-8.06-27.48-16.16-41.23-24.22
		c-15.33-8.98-30.68-17.92-46-26.91c-13.88-8.16-27.74-16.35-41.6-24.56c-4.56-2.7-9.29-5.09-13.56-8.33
		c-7.12-5.39-10.93-12.15-10.8-21.24c0.18-12.82,0.05-25.64,0.05-38.48c1.98-0.03,1.75,1.42,2.12,2.43
		c1.7,4.62,4.47,8.32,8.53,11.29c5.48,4.01,10.74,8.34,15.94,12.72c6.13,5.17,12.69,9.6,19.93,13.08
		c11.09,5.33,21.96,11.05,32.43,17.56c8.08,5.02,17.08,8.02,26.35,10.19c18.17,4.26,32.49,14.21,44.27,28.65
		c9.23,11.3,19.01,22.24,32.1,29.54c6.86,3.83,14.22,6.44,21.48,9.21c10.14,3.87,18.56,10.24,26.74,17.04
		c9.97,8.29,20.01,16.44,31.89,21.94c8.03,3.73,16.84,4.92,25.14,7.69c17.61,5.87,35.6,10.76,52.32,19.04
		c9.58,4.74,17.88,11.4,25.88,18.45c14.05,12.39,26.79,26.35,43.04,36.13c3.13,1.88,6.16,4,9.47,5.5
		c14.01,6.34,26.65,14.88,39,23.92c7.59,5.56,16.27,7.95,25.48,8.91c3.14,0.33,6.12,1.19,9.04,2.49c7.91,3.51,16.21,5.48,24.84,6.61
		c15.66,2.05,30.15-1.64,44.49-7.31c11.56-4.57,23.39-8.48,34.95-13.06c18.58-7.37,36.47-16.15,52.45-28.32
		c4.21-3.21,7.7-7.36,11.47-11.13c8.48-8.48,17.2-16.6,28-22.17c5.76-2.97,11.76-5.36,17.79-7.68c10.09-3.87,19.74-8.67,29.36-13.57
		c10.56-5.38,21.61-9,33.56-10.06c8.19-0.72,16.58-1.54,23.46-7.05c4.98-3.99,8.93-9.02,12.82-13.91
		c6.42-8.09,14.08-13.5,24.36-14.79c7.27-0.91,13.09-4.41,18.2-9.38c7.86-7.64,15.05-16.05,24.26-22.19
		c11.74-7.82,23.43-15.78,36.74-20.85c5.78-2.2,11.95-2.57,17.98-3.39c10-1.36,19.23-4.95,28.36-8.91
		c14.56-6.32,27.99-14.48,40.01-24.95c9.55-8.33,20.42-14.79,31.34-21.1c9.06-5.24,18.33-10.11,27.42-15.29
		c6.07-3.46,10.84-8.19,13.13-15.02C1072.65,560.98,1073.28,561,1073.9,561.01z" />
                  <path :fill="'#' + $parent.playersScoreboard[0].color" class="st1" d="M1073.9,561.01c0.02,12,0.03,24,0.06,36c0.02,11.22-5.29,19.37-14.79,24.77
		c-10.97,6.24-22.15,12.13-33.24,18.17c-22.62,12.31-45.23,24.64-67.87,36.92c-13.15,7.13-26.37,14.13-39.51,21.28
		c-21.75,11.82-43.45,23.73-65.19,35.57c-12.4,6.76-24.85,13.44-37.27,20.17c-23.37,12.67-46.73,25.37-70.11,38.03
		c-13.45,7.28-26.95,14.46-40.38,21.76c-21.46,11.67-42.87,23.41-64.32,35.1c-12.11,6.6-24.25,13.14-36.39,19.69
		c-12.87,6.95-25.7,13.97-38.63,20.79c-10,5.28-20.78,7.54-32.07,7.78c-12.4,0.26-24.63-0.77-35.92-6.22
		c-7.75-3.74-15.1-8.34-22.56-12.69c-18.64-10.88-37.24-21.82-55.85-32.75c-13.74-8.07-27.46-16.19-41.21-24.25
		c-15.18-8.9-30.39-17.75-45.58-26.64c-13.75-8.06-27.48-16.16-41.23-24.22c-15.33-8.98-30.67-17.93-46-26.91
		c-13.75-8.06-27.46-16.18-41.21-24.24c-15.18-8.9-30.39-17.75-45.58-26.64c-13.75-8.06-27.48-16.16-41.23-24.22
		c-15.33-8.98-30.68-17.92-46-26.91c-13.88-8.16-27.74-16.35-41.6-24.56c-4.56-2.7-9.29-5.09-13.56-8.33
		c-7.12-5.39-10.93-12.15-10.8-21.24c0.18-12.82,0.05-25.64,0.05-38.48c1.98-0.03,1.75,1.42,2.12,2.43
		c1.7,4.62,4.47,8.32,8.53,11.29c5.48,4.01,10.74,8.34,15.94,12.72c6.13,5.17,12.69,9.6,19.93,13.08
		c11.09,5.33,21.96,11.05,32.43,17.56c8.08,5.02,17.08,8.02,26.35,10.19c18.17,4.26,32.49,14.21,44.27,28.65
		c9.23,11.3,19.01,22.24,32.1,29.54c6.86,3.83,14.22,6.44,21.48,9.21c10.14,3.87,18.56,10.24,26.74,17.04
		c9.97,8.29,20.01,16.44,31.89,21.94c8.03,3.73,16.84,4.92,25.14,7.69c17.61,5.87,35.6,10.76,52.32,19.04
		c9.58,4.74,17.88,11.4,25.88,18.45c14.05,12.39,26.79,26.35,43.04,36.13c3.13,1.88,6.16,4,9.47,5.5
		c14.01,6.34,26.65,14.88,39,23.92c7.59,5.56,16.27,7.95,25.48,8.91c3.14,0.33,6.12,1.19,9.04,2.49c7.91,3.51,16.21,5.48,24.84,6.61
		c15.66,2.05,30.15-1.64,44.49-7.31c11.56-4.57,23.39-8.48,34.95-13.06c18.58-7.37,36.47-16.15,52.45-28.32
		c4.21-3.21,7.7-7.36,11.47-11.13c8.48-8.48,17.2-16.6,28-22.17c5.76-2.97,11.76-5.36,17.79-7.68c10.09-3.87,19.74-8.67,29.36-13.57
		c10.56-5.38,21.61-9,33.56-10.06c8.19-0.72,16.58-1.54,23.46-7.05c4.98-3.99,8.93-9.02,12.82-13.91
		c6.42-8.09,14.08-13.5,24.36-14.79c7.27-0.91,13.09-4.41,18.2-9.38c7.86-7.64,15.05-16.05,24.26-22.19
		c11.74-7.82,23.43-15.78,36.74-20.85c5.78-2.2,11.95-2.57,17.98-3.39c10-1.36,19.23-4.95,28.36-8.91
		c14.56-6.32,27.99-14.48,40.01-24.95c9.55-8.33,20.42-14.79,31.34-21.1c9.06-5.24,18.33-10.11,27.42-15.29
		c6.07-3.46,10.84-8.19,13.13-15.02C1072.65,560.98,1073.28,561,1073.9,561.01z" />
                </svg>
              </div>
            </div>
          </div>

          <div id="score" v-if="$parent.playersScoreboard.length > 1">
            <TransitionGroup name="scoreboard" class="scoreboard" tag="ul">
              <li v-for="player in $parent.playersScoreboard.slice(1)" class="userItem" :key="player.dbid">
                <player-avatar :player="player" :scale="2.1" :showUsername="false" class="mr-4" />
                <div class="player-box" :style="{ backgroundColor: `#${player.color}` }">
                  <span class="username">{{ player.username }}</span>
                  <span class="score">{{Math.round(player.score)}}</span>
                </div>
              </li>
            </TransitionGroup>
          </div>

          <div id="winMensajeJuntos">{{ $t('exp_trivia.cityTilesBrief') }} </div> 
          <div id="winMensajeCiudadGanadora"> {{ $t('exp_trivia.cityTilesWinner') }} </div>

        </template>
        <template v-else>
          <!-- Scoreboard Tie -->
          <Transition name="scoreboard" class="scoreboard tie" tag="ul">
            <div class="final scoreboard tie">
              <svg width="189" height="189" viewBox="0 0 189 189" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_2966_37293)">
                  <path
                    d="M167 89.5C167 129.54 134.54 162 94.5 162C54.4639 162 22 129.54 22 89.5C22 49.4639 54.4639 17 94.5 17C134.54 17 167 49.4639 167 89.5Z"
                    fill="#FFCC4D" />
                  <path
                    d="M91.7287 87.939C91.0198 87.363 90.0088 87.3469 89.2717 87.8826C89.2234 87.9188 84.1967 91.5156 74.3609 91.5156C64.5291 91.5156 59.4984 87.9188 59.4581 87.8906C58.721 87.3388 57.706 87.3549 56.9971 87.9269C56.2802 88.4988 56.0385 89.4816 56.4091 90.3194C56.6508 90.8631 62.4508 103.599 74.3609 103.599C86.2751 103.599 92.071 90.8631 92.3127 90.3194C92.6833 89.4856 92.4416 88.5109 91.7287 87.939ZM132.006 87.939C131.298 87.363 130.291 87.3428 129.549 87.8826C129.501 87.9188 124.47 91.5156 114.639 91.5156C104.811 91.5156 99.7762 87.9188 99.7359 87.8906C98.9988 87.3388 97.9878 87.3549 97.2749 87.9269C96.558 88.4988 96.3163 89.4816 96.6869 90.3194C96.9285 90.8631 102.729 103.599 114.639 103.599C126.553 103.599 132.349 90.8631 132.59 90.3194C132.961 89.4856 132.719 88.5109 132.006 87.939ZM110.611 129.78H78.3887C76.1653 129.78 74.3609 127.979 74.3609 125.752C74.3609 123.524 76.1653 121.724 78.3887 121.724H110.611C112.838 121.724 114.639 123.524 114.639 125.752C114.639 127.979 112.838 129.78 110.611 129.78ZM46.1664 73.3906C43.9431 73.3906 42.1387 71.5862 42.1387 69.3629C42.1387 67.1435 43.931 65.3431 46.1503 65.3351C46.7787 65.327 60.5255 64.9887 71.1427 50.8351C72.4759 49.0629 74.9973 48.6923 76.7816 50.0295C78.5619 51.3627 78.9203 53.8881 77.5871 55.6684C64.4445 73.1892 46.9076 73.3906 46.1664 73.3906ZM142.833 73.3906C142.092 73.3906 124.559 73.1892 111.416 55.6684C110.079 53.8881 110.442 51.3627 112.222 50.0295C113.998 48.6883 116.52 49.0548 117.857 50.8351C128.494 65.0169 142.269 65.331 142.853 65.3351C145.069 65.3592 146.857 67.1717 146.845 69.383C146.837 71.6023 145.048 73.3906 142.833 73.3906Z"
                    fill="#664500" />
                </g>
                <defs>
                  <filter id="filter0_d_2966_37293" x="0" y="0" width="189" height="189" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha" />
                    <feOffset dy="5" />
                    <feGaussianBlur stdDeviation="11" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.19 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2966_37293" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2966_37293" result="shape" />
                  </filter>
                </defs>
              </svg>
              <div class="dead-heat is-uppercase">{{ $t('exp_trivia.deadHeat') }}</div>
              <div class="no-player-won has-text-weight-bold">{{ $t('exp_trivia.noPlayerWon') }}</div>
            </div>
          </Transition>
        </template>

      </section>
    </Transition>

  </div>
</template>

<script>
import gsap from "gsap";
import AnimatedNumber from "../AnimatedNumber.vue";
import ProgressCircle from '../ProgressCircle.vue';
import PlayerAvatar from '../PlayerAvatar.vue';
import "./screenTriviaCityTilesGame.scss";

export default {
  components: {
    AnimatedNumber,
    ProgressCircle,
    PlayerAvatar,
  },

  data() {
    return {
      lastScores: [0, 0, 0, 0],
      lastUrlCities: [],
      ocultar: true,
    };
  },

  computed: {
    GetAllCities() {
      return this.$parent.options.animations;
    },
    questionState() {
      return this.$parent.questionState;
    },
    playersScores() {
      let scores = [];
      for (let i = 0; i < this.$parent.players.length; i++) {
        scores[i] = this.$parent.players[i].correctAnswers;
      }
      return scores;
    },
    winnerIndex() {
      for (let i = 0; i < this.$parent.players.length; i++) {
        if (this.$parent.players[i].username == this.$parent.playersScoreboard[0].username) return i;
      }
      return -1;
    }
  },

  methods: {
    //Render de ciudades
    GetAllCityStates(animationIndex) {
      return this.GetAllCities[animationIndex].states;
    },
    GetCity(animationIndex, stateIndex) {
      return this.GetAllCities[animationIndex].states[stateIndex].animation;
    },
    GetTransition(playerScore) {
      if (playerScore <= 1) return this.$parent.options.transitions[0].animation;
      else return this.$parent.options.transitions[playerScore - 1].animation;
    },
    ScoreOfPlayer(playerIndex) {
      return this.$parent.players[playerIndex].correctAnswers;
    },
    ThisCityExists(playerIndex) {
      if (this.ScoreOfPlayer(playerIndex) <= this.GetAllCities[playerIndex].states.length - 1) return true;
      else return false;
    },
    CityRenderClass(playerIndex, cityIndex) {
      if (this.ScoreOfPlayer(playerIndex) != cityIndex) return "hidden-city";
      else return "";
    },
    beforeEnterCityChange(el) {
      el.style.opacity = 0;
    },
    enterCityChange(el, done) {
      gsap.to(el, {
        opacity: 1,
        duration: 1,
        onComplete: done,
        delay: 0.8,
      });
    },
    leaveCityChange(el, done) {
      gsap.to(el, {
        opacity: 0,
        duration: 1,
        onComplete: done,
        delay: 0.8,
      });
    },

    // Animaciones
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "translateX(-100px)";
    },
    enter(el, done) {
      gsap.to(el, {
        opacity: 1,
        x: 0,
        duration: 0.8,
        onComplete: done,
        delay: el.dataset.index * 0.7
      });
    },
    leave(el, done) {
      gsap.to(el, {
        opacity: 0,
        x: -20,
        duration: 0.2,
        onComplete: done,
      });
    },
  },


  watch: {
    playersScores(currentScores) {
      for (var i = 0; i < this.$parent.players.length; i++) {
        if (currentScores[i] > this.lastScores[i]) {
          let transition = this.$refs["transition" + i][0];
          this.lastScores[i] = this.playersScores[i];

          if (currentScores[i] < 6) {
            transition.load();
            transition.pause();
            transition.currentTime = 0;
            transition.play();
          }
        }
      }
    },
    questionState(newVal) {
      if (newVal == 0) {
        this.lastScores = [0, 0, 0, 0];
      }
    }
  }

}
</script>